import React from "react";

export default function PrivacySection() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Политика конфиденциальности сайта
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Политика конфиденциальности
            </span>
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            Настоящая Политика устанавливает особенности сбора и обработки
            Обществом с ограниченной ответственностью Orzu Imkon (далее –
            Компания) данных физических лиц – посетителей сайта
            https://www.orzuimkon.com/ (далее – Сайт).
          </p>
          <p>
            Настоящая Политика применяется исключительно к информации, которая
            была получена Компанией в результате использования и/или посещения
            Сайта физическими лицами (далее – Посетители).
          </p>

          <p>
            При посещении Сайта, в частности раздела «Обратная связь» или любого
            другого раздела Сайта, подразумевающего получение обработку
            Компанией персональных данных Посетителей, Посетитель в полном
            объеме принимает условия настоящей Политики и выражает свое
            добровольное определенное согласие на обработку персональных данных
            способом и в целях как это описано в настоящей Политике. Если
            Посетитель не согласен с настоящей Политикой, Компания просит
            отказаться Посетителя от использования соответствующего раздела
            Сайта.
          </p>

          <p>
            Компания вправе в одностороннем порядке вносить изменения в
            настоящую Политику, информируя об этом Посетителей путем
            опубликования указанных изменений на Сайте.
          </p>
          <br />
          <h2 className="font-bold text-xl mb-2">
            1. Получаемая и используемая информация Посетителей, а также цели ее
            использования.
          </h2>
          <p>
            В рамках настоящей Политики конфиденциальности под информацией
            Посетителя понимается: персональная информация, которую Посетитель
            самостоятельно предоставляет Компании при использовании
            соответствующих разделов Сайта (Имя, Фамилия, адрес электронной
            почты и т.д.), а также автоматически передаваемые данные в процессе
            посещения Сайта, в том числе, но не ограничиваясь: IP-адрес,
            сведения о мобильном устройстве, с которого осуществляется доступ к
            Сайту и т.д.
          </p>
          <p>
            При посещении Сайта может быть запрошена и получена следующая
            информация:
          </p>
          <p>
            <span>Информация о Посетителе.</span> При создании посещении
            соответствующего раздела Сайта, Компанией запрашивается информация о
            Посетителе, например, ФИО, адрес электронной почты Посетителя.
            Компания оставляет за собой право запросить дополнительную
            информацию.
          </p>
          <p>
            <span>Информация об устройстве.</span> Компания оставляет за собой
            право по сбору данных об устройствах Посетителей включая, но не
            ограничиваясь: IP адреса устройства, модель мобильного устройства,
            версия операционной системы, уникальные идентификаторы устройства, а
            также данные о мобильной сети и номер мобильного телефона.
          </p>
          <p>
            Информация, полученная Компанией от Посетителя, может быть
            использована Компанией для предоставления Посетителю
            персонализированных сервисов, в статистических и исследовательских
            целях, а также для улучшения Сайта и связанных с ним сервисов.
          </p>
          <p>
            При использовании информации Посетителей Сайта руководствуется
            настоящей Политикой конфиденциальности, а также действующим
            законодательством Республики Узбекистан.
          </p>
          <br />
          <h2 className="font-bold text-xl mb-2">
            2. Предоставление информации Посетителей третьим лицам
          </h2>
          <p>
            Компания гарантирует, что не передает полученную от Посетителей
            информацию третьим лицам за исключением случаев, предусмотренных
            действующим законодательством Республики Узбекистан или вытекающих
            из технических особенностей сервисов, предоставляемых Посетителям
            Сайта. При этом лица, правомерно получающие информацию о Посетителях
            Сайта в силу технических особенностей, обязуются соблюдать настоящую
            Политику, а также нормы действующего законодательства Республики
            Узбекистан.
          </p>
          <br />
          <h2 className="font-bold text-xl mb-2">
            3. Меры безопасности, используемые для сохранения конфиденциальности
            информации
          </h2>
          <p>
            Компания гарантирует, что Компанией предпринимаются все возможные
            меры для обеспечения безопасности и защиты информации Посетителей от
            несанкционированных попыток доступа, изменения, раскрытия или
            уничтожения, а также иных видов ненадлежащего использования.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
