import * as React from "react";
import Layout from "../components/layout";
import PrivacySection from "../components/content-section/privacy-section";

const ContactPage = () => {
  return (
    <Layout>
      <PrivacySection />
    </Layout>
  );
};

export default ContactPage;
